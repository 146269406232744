function createObject() {
  let example = {
    id: "LBST_ZV_Stempeln_35p",
    workOrder: `Links sehen Sie ein Video. Rechts finden Sie eine Frage dazu.`,
    video: require("@/assets/zv/Stempeln/Punktebild_35.mp4"),
    elements: [
      [
        {
          question: "Wie viele Punkte haben Sie gesehen?",
          answers: [
            {
              correct: false,
              text: "25",
              response:
                "Die Anzahl der Plättchen ist größer. Schauen Sie sich das Video noch einmal an und versuchen Sie auf die Struktur der Plättchen zu achten. Dies kann Ihnen helfen die richtige Anzahl zu finden.",
            },
            {
              correct: true,
              text: "35",
              response:
                "Auf dem Video blinken kurz 35 Plättchen auf. Das haben Sie richtig erkannt. Auch für Kinder ist es wichtig, dass sie lernen größere Anzahlen schnell zu erkennen. Dies geschieht durch das Gruppieren und Strukturieren von Mengen.",
            },
            {
              correct: false,
              text: "50",
              response:
                "Die Anzahl der Plättchen ist kleiner. Schauen Sie sich das Video noch einmal an und versuchen Sie auf die Struktur der Plättchen zu achten. Dies kann Ihnen helfen die richtige Anzahl zu finden.",
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
