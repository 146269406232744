function createObject() {
  let example = {
    id: "LBST_ZV_Sortieren_Materialhandlung",
    left: "Am Material",
    right: "abstrakt",
    showGroupNames: false,
    maximalAmountElementsInGroup: 4,
    numberOfPlaceholder: 4,
    checkAfterHowManyItems: 4,
    checkAfterExactItemCount: true,
    workOrder: `Hier sehen Sie vier Abbildungen zur Materialhandlung von Kindern. Ziehe Sie die Bilder auf die Linie und sortieren Sie sie von der konkreten Materialhandlung hin zu einer mentalen Vorstellung dieser. Sobald Sie alle vier Bilder angeordnet haben, drücken Sie auf „Überprüfen“, um eine Rückmeldung zu erhalten.`,
    possibleCategories: [
      {
        id: 1,
        name: "1",
      },
      {
        id: 2,
        name: "2",
      },
      {
        id: 3,
        name: "3",
      },
      {
        id: 4,
        name: "4",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/zv/Sortieren/material/01.png"),
        rank: 4,
        flex: 3,
      },
      {
        id: 2,
        image: require("@/assets/zv/Sortieren/material/02.png"),
        rank: 1,
        flex: 3,
      },
      {
        id: 3,
        image: require("@/assets/zv/Sortieren/material/03.png"),
        rank: 2,
        flex: 3,
      },
      {
        id: 4,
        image: require("@/assets/zv/Sortieren/material/04.png"),
        rank: 3,
        flex: 3,
      },
    ],
  };

  const richtig =
    "Prima, Sie haben die Bilder in der richtigen Reihenfolge zur Abstrahierung der Materialhandlung sortiert! ";

  const fehler = "Das ist noch nicht ganz richtig. ";

  const error = "Diese Anordnung ist noch nicht richtig. ";

  //const diaReihe =
    "Es könnte sein, dass Sie die Bilder von der mentalen Handlung zur direkten Handlung am Material sortiert haben.";

  const tipEins =
    "Schauen Sie sich die Einordnung von dem Bild der Einzelarbeit an den Plättchen nochmal an. ";

 // const tipZwei =
    "Schauen Sie sich die Einordnung  von dem Bild der Partnerarbeit ohne Sichtschutz nochmal an. ";

  const tipDrei =
    "Schauen Sie sich die Einordnung von dem Bild der Partnerarbeit mit Sichtschutz nochmal an. ";

  const tipVier =
    "Schauen Sie sich die Einordnung von dem Bild der mentalen Handlung (Gedankenblase) nochmal an. ";

  const tipVor =
    "Überlegen Sie in welchen Bildern das Kind noch stark am Material handelt und in welchen Bildern die Handlung nicht mehr direkt am Material stattfindet. Versuche Sie die Bilder in dieser Reihenfolge zu sortieren. ";

  const tipReihe =
    "Beginnen Sie mit der Handlung, welche am stärksten am Material orientiert ist, links und sortieren Sie indem die Handlungen immer abstrakter werden nach rechts. ";

  example.responses = [
    {
      id: "1234",
      text: richtig,
    },
    {
      id: "1432",
      text: error + tipVor,
    },
    {
      id: "2143",
      text: error + tipVor,
    },
    {
      id: "2413",
      text: error + tipVor,
    },
    {
      id: "3142",
      text: error + tipVor,
    },
    {
      id: "3214",
      text: error + tipVor,
    },
    {
      id: "3241",
      text: error + tipVor,
    },
    {
      id: "4132",
      text: error + tipVor,
    },
    {
      id: "4213",
      text: error + tipVor,
    },
    {
      id: "4231",
      text: error + tipVor,
    },
    {
      id: "4312",
      text: error + tipVor,
    },
    {
      id: "1423",
      text: fehler + tipVier,
    },
    {
      id: "4123",
      text: fehler + tipVier,
    },
    {
      id: "1324",
      text:
        fehler +
        tipDrei +
        "Überlegen Sie in welchem Bild die Materialhandlung für das rechte Kind abstrakter ist.",
    },
    {
      id: "2134",
      text: fehler + tipEins,
    },
    {
      id: "2314",
      text: fehler + tipEins,
    },
    {
      id: "3124",
      text: fehler + tipDrei,
    },
    {
      id: "1243",
      text: fehler + tipVier,
    },
    {
      id: "1342",
      text: fehler + tipVor,
    },
    {
      id: "2431",
      text: fehler + tipVor,
    },
    {
      id: "3412",
      text: fehler + tipVor,
    },
    {
      id: "3421",
      text: fehler + tipVor,
    },
    {
      id: "4321",
      text: fehler + tipReihe,
    },
    {
      id: "2341",
      text:
        fehler +
        tipEins +
        "Überlegen Sie in welchem Bild das blonde Kind wirklich konkret selbst am Material handelt.",
    },
  ];
  return example;
}

export default createObject();
