<template>
  <div>
    <h2 class="faledia-caption">Darstellungen vernetzen</h2>
    <v-card outlined>
      <v-card-title>Was sollen Schüler:innen lernen? </v-card-title>
      <v-card-text
        >Die Kinder können…
        <ul>
          <li>
            Darstellungsformen und ihre strukturellen Hintergründe erfassen.
          </li>
          <li>
            die Darstellung einer Zahl flexibel in eine andere Darstellung
            übertragen.
          </li>
          <li>Anzahlen simultan und quasisimultan erfassen.</li>
          <li>
            Anzahlen aufgrund einer unterstützenden Strukturierung schnell
            erfassen.
          </li>
          <li>den Darstellungswechsel versprachlichen.</li>
          <li>mentale Operationen mit Zahlen vornehmen.</li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Als weiteres zentrales Merkmal des Zahlverständnisses gilt die Fähigkeit,
      zwischen verschiedenen Formen der Darstellung wechseln zu können und sich
      intensiv mit diesen Darstellungsformen auseinanderzusetzen. Zu den
      Darstellungsweisen zählen:
    </p>
    <p>
      <strong>Mathesprache:</strong> Unter Mathesprache wird die
      Verschriftlichung einer Zahl verstanden – die sogenannten Zahlzeichen. Am
      häufigsten Verwendung findet die sogenannte Normalform (23). Neben dieser
      ‚Normalform‘ einer Zahl lassen sich weitere Schreibweisen unterscheiden,
      wie z. B. ‚2Z 3E‘ (Schreibweise mit Zehnern und Einern) oder 20 + 3
      (Additive, stellengerechte Zerlegung der Zahl).
    </p>
    <p>
      <strong>Sprache:</strong> Im Gegensatz zur Notation von Zahlen, die
      regelgeleitet unter Benutzung des Stellenwertsystems und der arabischen
      Ziffern erfolgt, ist die Zahlwortbildung im deutschen Sprachraum von
      Unregelmäßigkeiten betroffen und unterscheidet sich z. T. erheblich von
      der Zahlwortbildung in anderen Sprachräumen. Bezogen auf zweistellige
      Zahlwörter ist das deutsche Zahlwortsystem beispielsweise durch
      Inkonsistenzen zwischen dem gesprochenen Zahlwort und der Abfolge der
      Ziffern in der geschriebenen Zahl (23 - twenty-three – drei-und-zwanzig)
      gekennzeichnet.
    </p>
    <p>
      <strong>Material:</strong> Bei der Darstellung größerer Zahlen greift man
      in der Regel nicht mehr auf Alltagsmaterialien, sondern auf didaktische
      Materialien zurück, welche die Strukturen unseres Dezimalsystems
      berücksichtigen. Dazu zählen zum Beispiel das Hunderterfeld, der
      Rechenrahmen oder auch Zehnersystemblöcke (Würfel, Stangen, Platten).
    </p>
    <p>
      <strong>Bilder:</strong> Auch hier gilt, dass zur Veranschaulichung
      sinnvollerweise bildliche Darstellungen genommen werden, die die Fünfer-
      bzw. die Zehnerstruktur und später die Hunderter- und die
      Tausenderstruktur zum Ausdruck bringen, wie zum Beispiel die Quadrat-,
      Strich-, Punktdarstellungen.
    </p>

    <div class="flex">
      <div class="image">
        <AppExpandableImage
          contain
          :src="require('@/assets/zv/darstellungen.png')"
          elementId="zv_darstellungen.png"
          parentId="zv"
        >
        </AppExpandableImage>
      </div>

      <p>
        Wichtig ist, dass diese Darstellungsweisen vornehmlich vernetzt und
        nicht voneinander isoliert betrachtet werden sollten. Übersetzungen
        sowie entsprechende Rückübersetzungen und somit die Vernetzungen
        zwischen den Ebenen sind notwendig. Diese mentalen Vernetzungen von
        Darstellungen unterstützen den Vorstellungsaufbau.
      </p>
    </div>
    <p>
      In Bezug auf das Zahlverständnis kommt der Anzahlerfassung eine
      entscheidende Rolle zu. Hierbei wird zwischen der simultanen und
      quasi-simultanen Erfassung unterschieden (vgl. Wittmann & Müller, 2009).
      Bei der Simultanerfassung werden in der Regel bis zu 3 oder 4 ungeordnete,
      nur kurz dargebotene Elemente in ihrer Anzahl erfasst. Durch die
      Zeitbegrenzung ist eine rein zählende Vorgehensweise nicht mehr
      zielführend und nicht mehr möglich, sodass die Fähigkeit der
      Anzahlerfassung erfasst werden kann. Die quasi-simultane Anzahlerfassung
      hingegen findet auch bei einer größeren Menge an Elementen statt und
      geschieht durch Gruppierung und ein gedankliches Wiederzusammensetzen im
      Anschluss.
    </p>
    <p>
      Um das Gruppieren und Erfassen zu unterstützen, eignen sich strukturierte
      Darstellungen und vorstrukturierte Materialien (vgl. Schipper, 2008;
      Scherer & Moser Opitz, 2010). Beispiele sind hier das Hunderter-Punktefeld
      oder der Rechenrahmen, wobei die Teilmengen 5 und 10 durch die Art der
      Strukturierung diesen Prozess unterstützen.
    </p>

    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[0].id"
        :workOrder="LBSTStempeln[0].workOrder"
        :elements="LBSTStempeln[0].elements"
        :video="LBSTStempeln[0].video"
      >
        <template v-slot:alternative-content-0>
          <p>
            Versuchen Sie die Punkte zu Gruppen zusammen zufassen. Alternativ
            kann das Video auch pausiert werden.
          </p>
        </template>
        <template v-slot:alternative-content>
          <p>
            Versuchen Sie die Punkte zu Gruppen zusammen zufassen. Alternativ
            kann das Video auch pausiert werden.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>

    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[1].id"
        :workOrder="LBSTStempeln[1].workOrder"
        :elements="LBSTStempeln[1].elements"
        :video="LBSTStempeln[1].video"
      >
        <template v-slot:alternative-content-0>
          <p>
            Versuchen Sie die Punkte zu Gruppen zusammen zufassen. Alternativ
            kann das Video auch pausiert werden.
          </p>
        </template>
        <template v-slot:alternative-content>
          <p>
            Versuchen Sie die Punkte zu Gruppen zusammen zufassen. Alternativ
            kann das Video auch pausiert werden.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>

    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[2].id"
        :workOrder="LBSTStempeln[2].workOrder"
        :elements="LBSTStempeln[2].elements"
        :video="LBSTStempeln[2].video"
      >
        <template v-slot:alternative-content-0>
          <p>
            Versuchen Sie die Punkte zu Gruppen zusammen zufassen. Alternativ
            kann das Video auch pausiert werden.
          </p>
        </template>
        <template v-slot:alternative-content>
          <p>
            Versuchen Sie die Punkte zu Gruppen zusammen zufassen. Alternativ
            kann das Video auch pausiert werden.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>

    <p>
      Hier konnten Sie noch einmal selbst erfahren, wie wichtig eine
      Strukturierung ist, um Mengen schnell und sicher zu erfassen.
    </p>
    <p>
      In der Entwicklung des Zahlverständnisses sind die Finger ein bedeutsames
      Darstellungsmittel. Aufgrund ihrer besonderen Strukturierung („Kraft der
      Fünf“, dekadische Struktur) können die Kinder so erste grundlegende
      Erkenntnisse über Zahlen und Operationen sammeln (Gaidoschik, 2007). Auf
      dieser Grundlage kann einem verfestigten zählenden Rechnen vorgebeugt
      werden, da ein grundlegendes Stellenwertverständnis angelegt wird (mehr
      dazu im Kapitel Stellenwertverständnis) (Scherer, 1999). Nichtsdestotrotz
      stellt das zählende Rechnen im Anfangsunterricht eine angemessene und
      geläufige Strategie zur Anzahlerfassung dar. Diese auszubauen und sich im
      weiterführenden Unterricht anderer Erfassungsstrategien zu bedienen, ist
      eine wichtige Lernanforderung im Grundschulunterricht.
    </p>
    <p>
      Ziel in der Entwicklung der Kompetenz des Darstellungswechsels ist es,
      fortschreitend von der konkreten Materialhandlung hin zu einer mentalen
      Vorstellung zu abstrahieren (Wartha & Schulz 2011). Die Kinder durchlaufen
      dabei folgende Schritte in diesem Entwicklungsprozess:
    </p>
    <div style="background-color: white">
      <LernbausteinSortieren
        :id="LBST_Sortieren_Material.id"
        :workOrder="LBST_Sortieren_Material.workOrder"
        :elements="LBST_Sortieren_Material.elements"
        :responses="LBST_Sortieren_Material.responses"
        :left="LBST_Sortieren_Material.left"
        :right="LBST_Sortieren_Material.right"
      >
        <template v-slot:alternative-content>
          <p>
            Es ist wichtig, dass Schüler:innen lernen immer abstrakter mit
            Material zu handeln. So handeln sie zuerst direkt am Material.
            Danach lernen sie anderen Schüler:innen zu beschreiben, wie diese am
            Material handeln sollen. Zuerst mit Sicht auf das Material und die
            Handlung des Partners. Dann ohne Sicht auf Material und Handlung.
            Als letztes lernen Schüler:innen die Handlung nur noch mental
            nachzuvollziehen.
          </p>
          <p>
            In dieser Tabelle sehen Sie das 4-Phasenmodell, welches die
            einzelnen Schritte von der konkreten Handlung am Material hin zur
            mentalen Vorstellung der Materialhandlung zeigt:
          </p>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td><strong>Handlung am Material</strong></td>
                  <td>
                    Das Kind handelt selbst am Material und versprachlicht die
                    eigenen Handlungen
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong
                      >Beschreibung der Material-handlung mit Sicht auf
                      Material</strong
                    >
                  </td>
                  <td>
                    Das Kind diktiert dem Gegenüber die Handlung und beobachtet
                    die Ausführung dieser
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong
                      >Beschreibung der Material-handlung ohne Sicht auf
                      Material</strong
                    >
                  </td>
                  <td>
                    Das Kind diktiert dem Gegenüber die Materialhandlung, ohne
                    dieses zu sehen. Es muss sich den Prozess am Material
                    vorstellen
                  </td>
                </tr>
                <tr>
                  <td><strong>Symbolebene</strong></td>
                  <td>Handlung rein in der Vorstellungsebene</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinSortieren>
    </div>

    <p>
      Somit zeigt sich, dass die Materialhandlung eine wichtige Rolle spielt. Es
      sollte sich aber nach dem Vier-Phasen-Modell von Wartha und Schulz auch
      nach und nach davon abgelöst werden, um die Vorstellungsebene in den Fokus
      zu rücken.
    </p>

    <AppLiteraturZV />
    <AppBottomNavZV
      next="/zahlverstaendnis/hintergrundwissen/zusammenhaenge"
      back="/zahlverstaendnis/hintergrundwissen/grundvorstellungen"
    />
  </div>
</template>

<script>
import AppBottomNavZV from "@/common/AppBottomNavZV";
import AppLiteraturZV from "@/common/AppLiteraturZV";
import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinSortieren from "@/components/faledia/explorativeElemente/LernbausteinSortieren";
import AppExpandableImage from "@/common/AppExpandableImage";

import LBST_Stempeln_35p from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Stempeln_35p";
import LBST_Stempeln_21p from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Stempeln_21p";
import LBST_Stempeln_11p from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Stempeln_11p";
import LBST_Sortieren_Material from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Sortieren_Materialhandlung";

export default {
  components: {
    AppBottomNavZV,
    AppLiteraturZV,
    LernbausteinStempeln,
    LernbausteinSortieren,
    AppExpandableImage,
  },
  data: () => ({
    LBSTStempeln: [LBST_Stempeln_35p, LBST_Stempeln_21p, LBST_Stempeln_11p],
    LBST_Sortieren_Material,
  }),
};
</script>

<style>
.image {
  max-width: 30%;
  margin-inline-end: 5%;
}
.flex {
  display: flex;
  align-items: center;
  margin-block-end: 1%;
}
</style>
